import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvitationComponent } from './login/invitation/invitation.component';
import { InvitationldapComponent } from './login/invitationldap/invitationldap.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { Constants } from './documentary/doc.configuration';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: Constants.AppId + '/invitation', component: InvitationComponent },
  {
    path: Constants.AppId + '/invitationldap',
    component: InvitationldapComponent,
  },
  {
    path: Constants.AppId + '/resetpassword',
    component: ResetPasswordComponent,
  },
  { path: '**', component: LoginComponent }, // In case of not founded route (404 error)

  {
    path: 'container',
    loadChildren: () =>
      import('../app/container/container.module').then(
        (m) => m.ContainerModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
