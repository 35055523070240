import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { FileDto, FileModel } from '../../models/FileModel';
import { NavigatorService } from '../../../navigator.services';
import { ItCoreController } from '../../../controllers/ItCoreController';

@Component({
  selector: 'app-onlyoffice',
  templateUrl: './onlyoffice.component.html',
  styleUrls: ['./onlyoffice.component.scss']
})

export class OnlyofficeComponent implements OnInit {
  Avatar: any;
  config: any;
  show = false;
  ShowPassword = false;
  ItCoreController: ItCoreController;
  constructor(private dataService: DataService, private navigatorService: NavigatorService) {
    this.Avatar = null;
    this.config = null;
    this.ItCoreController = new ItCoreController(dataService);
  }

  ngOnInit() {
    this.Load();
  }

  async Load() {
    let documentId = this.GetParam('id');
    documentId = parseInt(documentId, 10);
    const uoId = parseInt(this.GetParam('uo'), 10);
    const shareToken = this.GetParam('sharetoken');
    const type = this.GetParam('type');
    var desktop=JSON.parse(this.GetParam('d'));
    let passwordVerify = false;
    if ((type === 'template' || ((documentId || shareToken) && uoId)) || type === 'attachment' || desktop) {
      const url = await this.ItCoreController.GetOnlyOfficeUrl();
      // tslint:disable-next-line: max-line-length
      let script =  url + '/web-apps/apps/api/documents/api.js';
      //script = (script.includes('http:')) ? script.replace('http', 'https') : script;

      const fileModel = new FileModel();
      fileModel.Dto = new FileDto();
      fileModel.Dto.Id = documentId;
      fileModel.Dto.UoId = uoId;
      fileModel.Dto.MimeType = type;
      fileModel.Dto.ShareToken = shareToken;
      
      fileModel.Dto.Desktop=desktop;
      const result = await this.dataService.OpenOnlyOfficeFile(fileModel);
      if (result && result.hasOwnProperty('document')) {
        const documentConfig = result?.document;
        const edit = documentConfig.permissions.edit;
        const review = edit;
        const download = documentConfig?.permissions?.download;
        passwordVerify = documentConfig?.permissions?.passwordVerify;
        this.config = {
          editorConfig: {
            document: {
              fileType: documentConfig?.fileType,
              key: documentConfig.key,
              title: documentConfig.title,
              url: documentConfig.url,
              permissions: {
                changeHistory: edit,
                comment: edit,
                download,
                edit,
                fillForms: edit,
                modifyContentControl: edit,
                modifyFilter: edit,
                print: download,
                review
              },
            },
            documentType: result?.documentType,
            editorConfig: {
              callbackUrl: result?.editorConfig?.callbackUrl,
              lang: result?.editorConfig?.lang,
              region: result?.editorConfig?.region,
              customization: {
                compactHeader: true,
                toolbarNoTabs: true,
                autosave: true,
                forcesave: true,
              },
              user: {
                id: result?.editorConfig?.user.id,
                name: result?.editorConfig?.user.name,
              }
            },
            height: '100%',
            width: '100%',
          },
          script,
        };
        if (passwordVerify === null || (passwordVerify !== null && passwordVerify === true)) {
          this.show = (this.config ? true : false);
        }
      } else {
        if (result && result.hasOwnProperty('message')) {
          this.navigatorService.ShowSnackBar(result?.message);
        } else if (result && result.hasOwnProperty('error')) {
          this.navigatorService.ShowSnackBar(result?.error);
        } else {
          this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
        }
      }
    }
  }


  GetParam(param: string): any {
    const location = window.location.href;
    const params = new URLSearchParams(location);
    let value = params.get(window.location.origin + window.location.pathname + '?' + param);
    if (!value) {
      value = (params.getAll(param)?.length > 0 ? params.getAll(param)[0] : null);
    }
    return value;
  }


}
