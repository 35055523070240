import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './app.config.service';
import { AuthGuardService } from './auth-guard.service ';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { ContainerModule } from './container/container.module';
import { EmptyContainerModule } from './documentary/pages/empty-container/empty-container.module';
import { InvitationComponent } from './login/invitation/invitation.component';
import { InvitationldapComponent } from './login/invitationldap/invitationldap.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WhiteLabelService } from './white-label.service';
import { SafeHtmlPipe } from './SafeHtmlPipe';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CommonModule } from '@angular/common';

export function AppInit(appConfigService: AppConfigService) {
  return () => appConfigService.Load();
}

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    LoginComponent,
    InvitationComponent,
    ResetPasswordComponent,
    InvitationldapComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContainerModule,
    EmptyContainerModule,
    AppRoutingModule,
    RouterModule,
    AppMaterialModule,
    CommonModule
  ],
  exports: [RouterModule, ContainerModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    AppConfigService,
    AuthGuardService,
    WhiteLabelService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInit,
      multi: true,
      deps: [AppConfigService],
    },
    HttpClient,
    FormBuilder,
    AuthService,
    provideCharts(withDefaultRegisterables()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
