<div id="loginPage" class="container-fluid m-0 vh-100">
  <div class="row h-100">

    <!-- Colonna sinistra: Logo -->
    <div class="col-12 col-md-6 d-flex align-items-center justify-content-center loginLogoDiv">
      <div class="col-12">
        <img class="loginLogo" [src]="whiteLabelservice.BrandLogo" />
      </div>
    </div>

    <!-- Colonna destra: Form -->
    <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
      <div class="w-100 px-4">

        <!-- Titolo login -->
        <div *ngIf="!isPasswordRecovery && !isNewPasswordGeneration" class="loginSubTitle row pb-3 pt-0 px-0">
          <div class="col-12">
            {{navigatorService.Dictionary?.LoginTitle}}
          </div>
        </div>

        <!-- Password Recovery -->
        <div *ngIf="isPasswordRecovery" class="loginSubTitle row pb-3 pt-0 px-0">
          <div class="col-12">
            <div class="icon-text-container" (click)="GoToLoginOrRecovery()">
              <img class="clickable-icon" src="../../../../assets/icon/arrow-left.svg" alt="Arrow Left">
              <span class="login-recovery">{{navigatorService.Dictionary?.PasswordRecovery}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }} </div>
        <div *ngIf="status=='error'" class="errorMessage" role="alert"> {{ message }} </div>
  
        <!-- Form di login -->
        <form *ngIf="!isPasswordRecovery && !isNewPasswordGeneration" novalidate [formGroup]="userForm" class="row m-0 p-0 form-horizontal" (ngSubmit)="Login()">
          <fieldset class="col-12 m-0 p-0">
            <div class="row m-0 p-0" style="line-height: 22px;">
              <div class="col-12 m-0 pb-1 pt-0 px-0 form-group">
                <div class="loginField">
                  <label class="fieldLabel">Username <label class="requiredIcon">*</label></label>
                  <input type="text" class="form-control" id="email" name="email" placeholder="Username" formControlName="username">
                  <div *ngIf="usernameControl.dirty && usernameControl.hasError('required')" class="errorMessage">{{navigatorService.Dictionary?.UsernameRequired}}</div>
                  <div *ngIf="usernameControl.dirty && usernameControl.hasError('minlength')" class="errorMessage">{{navigatorService.Dictionary?.UsernameSixCharacters}}</div>
                  <div *ngIf="usernameControl.dirty && usernameControl.hasError('invalidEmail')" class="errorMessage">{{navigatorService.Dictionary?.InvalidUsername}}</div>
                </div>
              </div>
              <div class="col-12 m-0 pb-3 pt-0 px-0 form-group">
                <div class="loginField">
                  <label class="fieldLabel">Password <label class="requiredIcon">*</label></label>
                  <div class="fieldPassword">
                    <div class="input-container">
                      <input [type]="hide ? 'password' : 'text'" class="form-control" id="password" name="password" placeholder="Password" formControlName="password">
                      <label class="hidePassword" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <img class="icon" [src]="hide ? '../../../../assets/icon/eye.svg' : '../../../../assets/icon/eye-slash.svg'" style="font-size: 18px;">
                      </label>
                    </div>
                  </div>
                  <div *ngIf="passwordControl.dirty && passwordControl.hasError('required')" class="errorMessage">{{navigatorService.Dictionary?.PasswordRequired}}</div>
                  <div *ngIf="passwordControl.dirty && passwordControl.hasError('minlength')" class="errorMessage">{{navigatorService.Dictionary?.PasswordSixCharacters}}</div>
                </div>
              </div>

              <div class="col-12 m-0 pb-4 pt-0 px-2 forgotPassword" (click)="GoToLoginOrRecovery()">
                {{navigatorService.Dictionary?.ForgotPassword}} <i class="material-icons">chevron_right</i>
              </div>

              <div class="col-12 m-0 p-0 form-group">
                <div class="row m-0 p-0">
                  <button type="submit" class="loginButton" [disabled]="userForm.invalid || Loading ">Login</button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>

        <!-- Form di recupero password -->
        <form *ngIf="isPasswordRecovery" novalidate [formGroup]="mailForm" class="row m-0 p-0 form-horizontal" (ngSubmit)="SendLinkResetPassword()">
          <fieldset class="col-12 m-0 p-0">
            <div class="row m-0 p-0" style="line-height: 22px;">
              <div class="col-12 m-0 pb-4 pt-0 px-0 form-group">
                <div class="loginFieldLast">
                  <label class="fieldLabel">Email <label class="requiredIcon">*</label></label>
                  <input type="text" class="form-control" id="email" name="email" placeholder="Email" formControlName="mail">
                  <div *ngIf="emailControl.dirty && emailControl.hasError('required')" class="errorMessage">{{navigatorService.Dictionary?.UsernameRequired}}</div>
                  <div *ngIf="emailControl.dirty && emailControl.hasError('minlength')" class="errorMessage">{{navigatorService.Dictionary?.UsernameSixCharacters}}</div>
                  <div *ngIf="emailControl.dirty && emailControl.hasError('invalidEmail')" class="errorMessage">{{navigatorService.Dictionary?.InvalidUsername}}</div>
                </div>
              </div>

              <div class="col-12 m-0 pb-3 pt-0 px-0 form-group">
                <div class="row m-0 p-0">
                  <button type="submit" class="loginButton" [disabled]="mailForm.invalid">{{navigatorService.Dictionary?.SendToEmail}}</button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>

      </div>
    </div>
  </div>
</div>
