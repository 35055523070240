<div class="header">
    <div class="logo">
            <img src="../../../../assets/icon/BrandLogo.png">
    </div>

    <div class="avatar">
        <img  src="{{Avatar}}"/>
    </div>
</div>
<div class="onlyoffice" *ngIf="show" >
    <onlyoffice [config]="config" ></onlyoffice>
    
</div>



