import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LinkTokenFilter, LinkTokenModel } from '../../models/LinkToken';
import { SettingsController } from '../../controllers/SettingsController';
import { LoginController } from '../../controllers/Login';
import { NavigatorService } from '../../navigator.services';
import { CommonService } from '../../common.service';
import { AuthService } from '../../auth.service';
import { AppConfigService } from '../../app.config.service';
import { ChatService } from '../../chat.service';
import { WhiteLabelService } from '../../white-label.service';
import { DataService } from '../../data.service';
import { Pages } from '../../documentary/doc.configuration';

@Component({
  selector: 'app-invitationldap',
  templateUrl: './invitationldap.component.html',
  styleUrls: ['./invitationldap.component.scss'],
})
export class InvitationldapComponent implements OnInit {
  public newPasswordForm: FormGroup;
  newPasswordControl: FormControl;
  confirmPasswordControl: FormControl;
  hide = true;
  private token: string;
  status: string;
  message: string;

  LinkTokenModel: LinkTokenModel;
  SettingsController: SettingsController;
  LoginController: LoginController;

  constructor(
    public formBuilder: FormBuilder,
    public navigatorService: NavigatorService,
    private commonService: CommonService,
    private dataService: DataService,
    private authService: AuthService,
    public configAppService: AppConfigService,
    public whiteLabelservice: WhiteLabelService,
    private chatService: ChatService
  ) {
    this.token = this.navigatorService.GetQueryParameter('token');

    this.newPasswordControl = this.formBuilder.control('', [
      Validators.required,
      Validators.minLength(6),
    ]);
    this.confirmPasswordControl = this.formBuilder.control('', [
      Validators.required,
      Validators.minLength(6),
    ]);
    this.newPasswordForm = this.formBuilder.group(
      {
        newPassword: this.newPasswordControl,
        confirmPassword: this.confirmPasswordControl,
      },
      {
        validators: this.commonService.MatchValidator(
          'newPassword',
          'confirmPassword'
        ),
      }
    );

    this.LinkTokenModel = new LinkTokenModel();
    this.SettingsController = new SettingsController(dataService);
    this.LoginController = new LoginController(authService, chatService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    if (this.token) {
      this.LinkTokenModel.Filter = new LinkTokenFilter();
      this.LinkTokenModel.Filter.Token = this.token;
      this.LinkTokenModel.Filter.Type = 'INVITATION';
      this.LinkTokenModel = await this.SettingsController.GetInvitationFirst(
        this.LinkTokenModel
      );
      if (
        !this.LinkTokenModel.Performed ||
        (this.LinkTokenModel.Performed && !this.LinkTokenModel.Dto)
      ) {
        this.navigatorService.GoToExternal(Pages.Login);
      }
    } else {
      this.navigatorService.GoToExternal(Pages.Login);
    }
    this.navigatorService.StopLoading();
  }

  async ConfirmPassword() {
    if (this.newPasswordForm.invalid) {
      return;
    }
    if (this.newPasswordForm.value.newPassword) {
      this.LinkTokenModel.Performed = false;
      this.LinkTokenModel.Dto.Password = this.newPasswordForm.value.newPassword;
      this.LinkTokenModel = await this.SettingsController.AcceptInvitation(
        this.LinkTokenModel
      );
      if (this.LinkTokenModel?.Performed) {
        if (
          this.LinkTokenModel?.Code === 404 ||
          this.LinkTokenModel?.Code === 202
        ) {
          this.status = 'error';
          this.message = this.LinkTokenModel?.Message;
        } else {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.AccountVerifiedSuccessfully
          );
          this.navigatorService.GoToExternal(Pages.Login);
        }
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
    }
  }
}
